import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { error } from 'console';

@Component({
  selector: 'vex-extra-board-step',
  templateUrl: './extra-board-step.component.html',
  styleUrls: ['./extra-board-step.component.scss']
})
export class ExtraBoardStepComponent implements OnInit  {
  public formExtraBoarding: FormGroup;

  filterBoardingLists$: { [key: string]: Observable<any[]> } = {};


    @Input() periods:any[]
  
  constructor( 
  private formBuilder: FormBuilder,private ContratService: ApiService,private fb: UntypedFormBuilder ) {
    
  }
  ngOnInit(): void {
    this.formExtraBoarding = new FormGroup({
    

      BoardArrayExtra: this.formBuilder.array([


      ]),


    });
    this.ContratService.getBoardsOfContrat(this.periods[0].contract_id).subscribe(respBoards => {

      console.log("respBoards extra",respBoards)
       respBoards.forEach
       this.filterBoardingLists$[0] = of([...respBoards]);
       this.periods.forEach((itemP,index)=>{
        console.log("respBoards extra index",index)

        this.filterBoardingLists$[itemP.code] = this.filterBoardingLists$[0].pipe(
          map(periods => periods.filter(item => item.code === itemP.code ))
          
        );

        if(index+1<this.periods.length){
          this.filterBoardingLists$[itemP.code].subscribe(data => {
            data.forEach(item=>{
              this.BoardArrayExtra.push(this.fb.group({
                codePeriod:itemP.code,
                extra_board_name:[item.extra_board_name],
                extra_board_id:[item.extra_board_id],
                period_name: [item.name],
                period_id: [item.period_id],
                contrat_id: [item.contrat_id],

                id: [item.id],
               board_id:[item.board_id],
               board_name:[item.board_name],
                definition: [item.definition],
                start_at: [item.start_at],
                end_at: [item.end_at]
              }));
            } )
   
          });
                
        }
       })

     })
  
   

}

showSuccessAlert(message): void {
  Swal.fire({
    title: 'Success',
    text: message,
    icon: 'success',
    confirmButtonText: 'Okay',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    allowOutsideClick: false
  })
 

}

showWarningAlert(message): void {
  const dialogswal=Swal.fire({
    title: 'Warning',
    text: message,
    icon: 'warning',
    confirmButtonText: 'Okay',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    allowOutsideClick: false
  })

 

}
showErrorAlert(): void {

  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
  });
 

}
public get BoardArrayExtra(): FormArray {
  return this.formExtraBoarding.get("BoardArrayExtra") as FormArray;

}

saveBoardingextra() {
  console.log("saveBoardingextra  ",this.formExtraBoarding.value) 

  if (this.formExtraBoarding.status=='INVALID') {
    return;
  }
  else{
    this.ContratService.updateBoardExtraofcontrat(this.formExtraBoarding.value).subscribe(
      responseboardextra => {
      console.log("Extra Board: ", responseboardextra)
     this.showSuccessAlert("Extra Board Saved Successfully ")
    },
    error=>{
      this.showErrorAlert()


    }
  
  )
    
  }
}
onChangeDestinationextraboard(selected,index) {
  console.log("selected  ",selected) 
  console.log("index  ",index) 

    const extra_board_id = this.BoardArrayExtra.at(index).get('extra_board_id');
    const extra_board_name = this.BoardArrayExtra.at(index).get('extra_board_name');

    extra_board_id.setValue(selected.board_id);

    extra_board_name.setValue(selected.board_name);

}
getCode(index){
  const periodGroup = this.BoardArrayExtra.at(index);
   let code=parseInt((periodGroup.get('codePeriod') as FormArray).value)+1

return  code.toString()

}




}
