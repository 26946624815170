import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigPanelFilterService {
  urlstaticlist:string
  urlGetListGroupe:string
  urlAgenceByGroup:string
  urlGetAgences:string
  constructor(private http: HttpClient) { 
    this.urlstaticlist=environment.urlstaticlist
    this.urlGetListGroupe=environment.urlGetListGroupe
    this.urlAgenceByGroup=environment.urlAgenceBygroup
    this.urlGetAgences=environment.urlGetAgences
  }
  
  getstatichotellist() {
    return this.http.get<any>(this.urlstaticlist + "hotel");
  }

  getstaticcitylist() {
    return this.http.get<any>(this.urlstaticlist + "city");
  }
  getstaticcountrylist() {
    return this.http.get<any>(this.urlstaticlist + "country");
  }
  gethotelcitylist(id) {
    return this.http.get<any>(this.urlstaticlist + "hotel", { params: { cityid: id } });
  }
  getcitycountrieslist(id) {
    return this.http.get<any>(this.urlstaticlist + "city", { params: { countryid: id } });
  }
  getgroupelist() {
    return this.http.get<any>(this.urlGetListGroupe);
  }
  ListAgencesByGroup(id) {
    return this.http.get<any>(this.urlAgenceByGroup + "/" +id);
  }
  ListAgences(){
    return this.http.get<any>(this.urlGetAgences)
  }
}
