import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavItemComponent } from './sidenav-item.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { ContratModule } from '../../../../app/pages/apps/contrat/contrat.module';
import { MiniErpModule } from '../../../../app/pages/apps/mini-erp/mini-erp.module';

@NgModule({
  declarations: [SidenavItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    ContratModule,MiniErpModule,
  ],
  exports: [SidenavItemComponent]
})


export class SidenavItemModule {
}
