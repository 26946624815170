import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationsUpdateComponent } from './reservations-update.component';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ReservationsUpdateComponent],
  exports: [ReservationsUpdateComponent]
})
export class ReservationsUpdateModule { }
