import { Injectable, Inject, Optional } from '@angular/core';
import {  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse} from '@angular/common/http';
import { JWTTokenService } from './JWTToken.service';
import { map, Observable,tap } from 'rxjs';
import { Router} from '@angular/router';

@Injectable()
export class UniversalAppInterceptor implements HttpInterceptor {

  constructor( private jwttokenService: JWTTokenService,public router: Router,) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    if(req.url!='https://moon.dotcom.tn/api/Login')
  {  const token = this.jwttokenService.getToken();
       req = req.clone({
      url:  req.url,
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
    return next.handle(req).pipe(
      tap({
        next: () => null,
        error: (error: HttpErrorResponse) => {
          if(error.error=="Expired Token")
            this.router.navigate(['login']); // Should Redirect Sign-In Page
         
        },
      })
    );
    
  }
}