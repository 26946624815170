<div class="flex items-center" mat-dialog-title>
    <h4 class="headline m-0 flex-auto">Cancellation Conditions</h4>
 
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>


  <mat-tab-group [selectedIndex]="selected.value"  
  >
  <mat-tab *ngFor=" let periodGroup of periodGroupes.controls; let groupIndex = index" [label]="TabPeriodes[groupIndex].name">
    <div class="flex flex-col">
      <form [formGroup]="formCancellation" >
        <div formArrayName="PeriodGroupes">

        <div [formGroupName]="groupIndex">

        <div formArrayName="CancellationConditions" class="line" 
        *ngFor="let itemConcell of getCancellationConditions(periodGroup).controls; let i = index" >
        <div [formGroupName]="i">

          <div>

       
        
            <div class="flex flex-col sm:flex-row sm:gap-4">

        
              <mat-form-field *ngIf="Category==='board'" class="flex-1" appearance="fill" style="width: 100%;">
                <mat-label>{{'Board' }}</mat-label>
                <input matInput formControlName="board_name" style="width: 100%;" readonly
                 >
               
              </mat-form-field>
              <mat-form-field *ngIf="Category==='roomType'" class="flex-1" appearance="fill" style="width: 100%;">
                <mat-label>{{'Room Type' }}</mat-label>
                <input matInput formControlName="room_type_name" style="width: 100%;" readonly
                 >
               
              </mat-form-field>
              <mat-form-field *ngIf="Category==='room'"class="flex-1" appearance="fill" style="width: 100%;">
                <mat-label>{{'Room' }}</mat-label>
                <input matInput formControlName="room_name" style="width: 100%;" readonly
                 >
               
              </mat-form-field>
         
  
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <!-- <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Min reservation nights' }}</mat-label>
                  <input matInput formControlName="min_nights" type="number" required>
                </mat-form-field>
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Max reservation nights' }}</mat-label>
                  <input matInput formControlName="max_nights" type="number" required>
                </mat-form-field> -->
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Min of days before arrival' }}</mat-label>
                  <input matInput formControlName="min_days_before_arrival" type="number" required>
                </mat-form-field>
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Max of days before arrival' }}</mat-label>
                  <input matInput formControlName="max_days_before_arrival" type="number" required>
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Nights or Price to bill' }}:</mat-label>

                  <input matInput formControlName="nights_to_bill" type="number" required>
                </mat-form-field>
                <!-- <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Night Value' }}</mat-label>
                  <input matInput formControlName="night_value" type="number" required readonly>
                </mat-form-field> -->
                <mat-form-field class="flex-1" appearance="fill">
                  <mat-label>{{'Operation' }}</mat-label>
                  <mat-select formControlName="operation" #operation >
                    <mat-option [value]="option.value" *ngFor="let option of options">
                      {{ option.display }}
                    </mat-option>
                  </mat-select>
                 
                </mat-form-field>
              </div>

        <button matInput class="flex-1" mat-button color="warn" (click)="RemoveitemCancell(periodGroup,i)">- {{'Remove'
          }}</button>
         
          </div>
        
        
        </div>
      
      </div>
      <div class="flex flex-col sm:flex-row sm:gap-4">
        <div class="flex items-center gap-1"  >
          <mat-slide-toggle (change)="Refundable(groupIndex)" [checked]=getRefundable(groupIndex)></mat-slide-toggle>
          <label>{{'Refundable' }}</label>
        </div>
        
      
      </div>
      <div class="flex flex-col sm:flex-row sm:gap-4">
        <div class="flex items-center gap-1" (click)="$event.stopPropagation(); ">
          <mat-slide-toggle (change)="NowShow(groupIndex)" [checked]=getNoShow(groupIndex)></mat-slide-toggle>
          <label>{{'No show' }}</label>
        </div>
        <mat-form-field *ngIf="getNoShow(groupIndex)" class="flex-1" appearance="fill">
          <mat-label>{{'Price to bill' }}</mat-label>
          <input matInput formControlName="no_show_nights_to_bill" type="number" required>
        </mat-form-field>
        <mat-form-field *ngIf="getNoShow(groupIndex)" class="flex-1" appearance="fill">
          <mat-label>{{'Operation' }}</mat-label>
          <mat-select formControlName="no_show_operation" #no_show_operation >
            <mat-option [value]="option.value" *ngFor="let option of options">
              {{ option.display }}
            </mat-option>
          </mat-select>
         
        </mat-form-field>
        <!--(change)="$event ? activateAgencie(row) : null"-->
        <!--activateAgencie(row);-->
      </div>
   
      <div class="flex flex-col sm:flex-row sm:gap-4">


        <!-- <div class="flex items-center gap-1" (click)="$event.stopPropagation(); ">
          <mat-slide-toggle (change)="BillPerStay(i,groupIndex)" [checked]=getBillStay(i,groupIndex)></mat-slide-toggle>
          <label>{{'Bill per stay' }}</label>

        </div>
        <mat-form-field *ngIf="getBillStay(i,groupIndex)" class="flex-1" appearance="fill">
          <mat-label>{{'Value to bill' }}</mat-label>
          <input matInput formControlName="bill_stay_value" type="number" required>

        </mat-form-field> -->
        <!--(change)="$event ? activateAgencie(row) : null"-->
        <!--activateAgencie(row);-->
      </div>
      <div class="flex flex-col sm:flex-row sm:gap-4">



        <div class="flex items-center gap-1" (click)="$event.stopPropagation(); ">
          <mat-slide-toggle (change)="FreeCancellation(groupIndex)" [checked]=getFreeCancel(groupIndex)></mat-slide-toggle>
          <label>{{'Free cancellation' }}</label>

        </div>
        <mat-form-field *ngIf="getFreeCancel(groupIndex)" class="flex-1" appearance="fill">
          <mat-label>{{'Nb night before' }}</mat-label>
          <input matInput formControlName="free_cancel_before" type="number" required>

        </mat-form-field>
        
        <!--(change)="$event ? activateAgencie(row) : null"-->
        <!--activateAgencie(row);-->
      </div>
    
    </div>
         
        </div>

      </form>
      <div>
        <button mat-button color="primary" (click)="AddnewrowConcell(periodGroup)">+ {{'Add New' }} </button>
      </div>
      <br>

<div class="flex flex-col sm:flex-row sm:gap-4">
  <div class="float-left" style="margin-left: 75%;">

    <button *ngIf="Type==='update'" [disabled]="disableButton" (click)="SaveCancellationConditions(periodGroup)" color="primary" mat-flat-button mat-button>{{'Update Cancellation Conditions' }}</button>
    <button *ngIf="Type==='insert'" [disabled]="disableButton" (click)="SaveCancellationConditions(periodGroup)" color="primary" mat-flat-button mat-button>{{'Add Cancellation Conditions' }}</button>
  </div>
</div>

    </div>
  </mat-tab>
</mat-tab-group>