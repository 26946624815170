import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from '@ngneat/until-destroy';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { TableColumn } from '../../../../@vex/interfaces/table-column.interface';
import { aioTableLabels } from '../../../../../src/static-data/aio-table-data';
import { Customer } from '../aio-table/interfaces/customer.model';
import { AffichageContratComponent } from './affichage-contrat/affichage-contrat.component';
import { ApiService } from './api.service';
import { CreateContratComponent } from './create-contrat/create-contrat.component';
import { Contrat } from './interfaces/contrat.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { scaleFadeIn400ms } from '../../../../../src/@vex/animations/scale-fade-in.animation';
import { ReplaySubject, Observable, filter, of, catchError, map, startWith, switchMap } from 'rxjs';
import { AlertComponent } from '../alert/alert.component';

@UntilDestroy({ checkProperties: true })
@UntilDestroy()

@Component({
  selector: 'vex-contrat',
  templateUrl: './contrat.component.html',
  styleUrls: ['./contrat.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms
  ],


})
export class ContratComponent implements OnInit {
  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Contrat[]> = new ReplaySubject<Contrat[]>(1);
  data$: Observable<Contrat[]> = this.subject$.asObservable();
  customers: any[];
  @Input()
  @Input()
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 20, 200];
  
  @Input() columns: TableColumn<Contrat>[] = [
    { label: 'Active', property: 'active', type: 'button', visible: true },
    { label: 'ID', property: 'id', type: 'number', visible: false },
    { label: 'Contract Name', property: 'name', type: 'text', visible: true },
    { label: 'Hotel Name', property: 'hotel_name', type: 'text', visible: true },
    { label: 'Start At', property: 'start_at', type: 'Date', visible: true },
    { label: 'End At', property: 'end_at', type: 'Date', visible: true },
    { label: 'Access', property: 'access', type: 'text', visible: true },
    { label: 'Version', property: 'version', type: 'number', visible: true },
    { label: 'Currency', property: 'currency', type: 'text', visible: true },
    { label: 'Market', property: 'market', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },

  ];

  dataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  Contrats: Contrat[] = []
  contrat = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private dialog: MatDialog,
    private ContratService: ApiService,
    private cd: ChangeDetectorRef) { }
  length: number = 20
  isLoading: boolean = false
  pageIndex = 0;
  page: any
  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }
  labels = [
    {
      text: 'Working',
      textClass: 'text-green',
      bgClass: 'bg-green-light',
      previewClass: 'bg-green'
    },
    {
      text: 'Errored',
      textClass: 'text-red',
      bgClass: 'bg-red-light',
      previewClass: 'bg-red'
    },
  ];

  getpagedata(page: any, pageSize: any) {

    console.log("page*: ", page, "pageSize*: ", pageSize)

    this.ContratService.getContratPagination(page, pageSize).subscribe(res => {
      console.log("resp pagination: ", res)
      this.contrat = res.items
      this.dataSource = new MatTableDataSource(this.contrat);
      this.cd.detectChanges()
      console.log("this.contrat", this.contrat)
      this.page = page;

      console.log("this.page**", this.page)

      this.length = parseInt(res.totalItems)
  

    },
      err => { console.log("hotel/getall error", err); }

    );
    return of(this.contrat.map(contrat => new Contrat(contrat)))

  }
  ngOnInit(): void {

    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<Contrat[]>(Boolean)
    ).subscribe(contrat => {
      this.contrat = contrat;
      this.dataSource.data = contrat;
    });

    console.log("contrat/contrat length ", this.contrat.length)
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));


  }

  activatecontrat(contrat: any) {

    console.log("Contrat before  ", contrat.active)

    this.ContratService.ActivateCONTRAT(contrat).subscribe(response => {
      console.log("Contratresponse ", response)
      if(response.message=='You already have an active contract inactive it first')
        {
          this.dialog.open(AlertComponent,{
            data: { message: 'You already have an active contract inactive it first' }
          
        
        })
        }
      let index = this.contrat.findIndex((ag) => ag['id'] == contrat.id)
      console.log("Contrat index ", index)
      contrat.active = !contrat.active
      this.contrat[index] = contrat;
      this.subject$.next(this.dataSource.data);
      console.log("Contrat/test after ", contrat.active)

    },
      err => { console.log("Contrat/test error", err); }
    );

    this.getpagedata(this.page, this.pageSize)
    this.cd.detectChanges()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;



    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.getpagedata(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
          ).pipe(catchError(() => new Observable(null)));
        }
        ), map((contrat) => {
          if (contrat == null) return [];
          this.contrat = this.contrat;
          this.isLoading = false;
          return contrat;
        })
      )
      .subscribe((contrat) => {
        this.contrat = this.contrat;
        this.dataSource = new MatTableDataSource(this.contrat);
      });



  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }
  createCustomer() {
    let dialogBoxSettings = {
      height: '80%',
      width: '100%',
      margin: '0 auto',
      disableClose: true,
      hasBackdrop: true,
    }
    this.dialog.open(CreateContratComponent, dialogBoxSettings).afterClosed().subscribe((contrat: Contrat) => {
      console.log("Contract created:", contrat)
      this.cd.detectChanges();
      this.getpagedata(this.page, this.pageSize);

    });
    this.getpagedata(this.page, this.pageSize);
    this.cd.detectChanges();

  }

  updateContract(customer) {
    let dialogBoxSettings = {
      height: '80%',
      width: '100%',
      margin: '0 auto',
      disableClose: true,
      hasBackdrop: true,
      data: customer
    }
    this.ContratService.updatedata(customer)
    this.ContratService.updatedataperiod(customer)

    console.log("contract to update: ", customer)
    this.dialog.open(AffichageContratComponent, dialogBoxSettings).afterClosed().subscribe(updatedcontrat => {
      if (updatedcontrat) {
        const index = this.Contrats.findIndex((existingcountries) => existingcountries.id === updatedcontrat.id);
        this.Contrats[index] = new Contrat(customer)

        this.subject$.next(this.Contrats);
        this.cd.detectChanges();
        this.getpagedata(this.page, this.pageSize);
      }
      this.cd.detectChanges();
      this.getpagedata(this.page, this.pageSize);
      this.cd.detectChanges();

    });
    this.cd.detectChanges();
    this.getpagedata(this.page, this.pageSize);

  }
  AffichageContrat(contrat) {
    let dialogBoxSettings = {
      height: '80%',
      width: '100%',
      margin: '0 auto',
      disableClose: true,
      hasBackdrop: true,
      data: contrat
    }
    this.dialog.open(AffichageContratComponent, dialogBoxSettings)
  }
  deleteCustomer(customer: Customer) {
    console.log("Contract to Deleted:", customer)

    this.ContratService.deletecontrat(customer.id).subscribe((Response: any) => {
      console.log("Contract Deleted", Response)
      this.getpagedata(this.page, this.pageSize);
      this.cd.detectChanges();

    })



  }

  deleteCustomers(customers: Customer[]) {
    customers.forEach(c => this.deleteCustomer(c));
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: Customer) {
    const index = this.customers.findIndex(c => c === row);
    this.customers[index].labels = change.value;
  }
}

