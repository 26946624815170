import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ConfigPanelFilterToggleComponent } from './config-panel-filter-toggle/config-panel-filter-toggle.component';
import { ConfigPanelFilterComponent } from './config-panel-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ReactiveComponentModule } from '@ngrx/component';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormElementsRoutingModule } from 'src/app/pages/ui/forms/form-elements/form-elements-routing.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from '../secondary-toolbar/secondary-toolbar.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageLayoutModule } from '../page-layout/page-layout.module';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';


@NgModule({
  declarations: [
    ConfigPanelFilterComponent,
    ConfigPanelFilterToggleComponent
  ],
 
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,

    MatRadioModule,
    MatSlideToggleModule,
    MatRippleModule,
    ReactiveComponentModule,
    MatSliderModule,
    FormElementsRoutingModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    FormsModule,
    MatTabsModule,
    PageLayoutModule,
    MatDialogModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatButtonToggleModule,
    ScrollbarModule,
    MatSidenavModule,

  ],
  exports: [
    ConfigPanelFilterComponent,
    ConfigPanelFilterToggleComponent
  ],
  providers: [DatePipe]
})
export class ConfigPanelFilterModule { }
