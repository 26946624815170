<vex-page-layout>

    <vex-secondary-toolbar current="BackOffice">
      <vex-breadcrumbs style="height: var(--toolbar-width);" [crumbs]="[' Permission Users
         ']" style="margin-top: 0px;" class="flex-auto"></vex-breadcrumbs>
  
    </vex-secondary-toolbar>
    <vex-page-layout-header class="pb-8 flex flex-col items-start justify-center padding"
      style="background-color:whitesmoke;">
      <div [class.container]="layoutCtrl.value === 'fullwidth'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
        class="w-full flex flex-col sm:flex-row justify-between">
        <div>
        </div>
        <!--  <div class="hidden sm:block">
        <mat-button-toggle-group [formControl]="layoutCtrl" class="mt-2 sm:mt-0" style="margin-top: -58px;
        margin-bottom: 14px;">
          <mat-button-toggle value="boxed">Boxed</mat-button-toggle>
          <mat-button-toggle value="fullwidth">Full-Width</mat-button-toggle>
        </mat-button-toggle-group>
      </div> -->
      </div>
    </vex-page-layout-header>
    <vex-page-layout-content [class.container]="layoutCtrl.value === 'fullwidth'"
      [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
      <div class="card overflow-auto -mt-16">
        <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    
         
          <div
            class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
            <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
            <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
              placeholder="Search..." type="search">
          </div>
          <span class="flex-1"></span>
          <button  class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Save"
          type="button" (click)="save()" [disabled]="!isModified">
          <mat-icon svgIcon="mat:save"></mat-icon>
        </button>
         
        </div>
        <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
  
          <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
            <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
            </ng-container>
            <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'contrat' " [matColumnDef]="column.property ">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox 
                  [checked]="row['contrat']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'profil'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                 
                  [checked]="row['profil']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'tour'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
               
                [checked]="row['tour']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'transfer'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
               
                [checked]="row['transfer']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
           
            <ng-container *ngIf="column.property === 'booking'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['booking']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
             <ng-container *ngIf="column.property === 'user'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['user']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
             <ng-container *ngIf="column.property === 'log'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['log']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'comparator'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['comparator']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'configuration'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['configuration']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'dashboard'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['dashboard']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'permission'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['permission']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'search'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['search']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'stop_sale'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['stop_sale']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'crm'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['crm']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'hotel'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['hotel']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'package'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['package']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'xml_out'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['xml_out']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'erp' " [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['erp']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'event'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['event']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
  
            <ng-container *ngIf="column.property === 'xml_in'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
                <mat-checkbox
                [checked]="row['xml_in']" (change)="onCheckboxChange($event, row, column.property,i)">
                </mat-checkbox>
              </td>
            </ng-container>
          </ng-container>
    
    
          <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
     
        </table>
      </div>
     
 
    </vex-page-layout-content>
  
  </vex-page-layout>
  
