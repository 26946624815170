import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertserviceService } from './alertservice.service';




@Component({
  selector: 'vex-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})




export class AlertComponent implements OnInit {
 
  static id = 100;
  message: string = '';
  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  alerts: { message: string, type: string }[] = [];
  constructor(@Inject(MAT_DIALOG_DATA)  public data: { message: string } ,
  private dialogRef: MatDialogRef<AlertComponent>,private dialog: MatDialog,
  private fb: FormBuilder,
  private alertService: AlertserviceService) { 
    this.alertService.alerts.subscribe(alerts => {
    this.alerts = alerts;
  }); }

  ngOnInit(): void {
   
  
  this.form = this.fb.group({
    id: [AlertComponent.id++],
  })
  if (this.mode === 'update') {
    console.log("this.mode",this.mode)
  }
  }

  isCreateMode() {
    return this.mode === 'create';
  }
  
  isUpdateMode() {
    return this.mode === 'update';
  }
  openAlert() {
    if (this.message === 'solde insuffisant') {
      this.dialog.open(AlertComponent, {
        data: { message: 'Sorry Solde Agency Insuffisant !!' }
      });
    } else if (this.message ==='Policy not existing') {
      this.dialog.open(AlertComponent, {
      data: { message: 'Not Policy added for this reservation !!' }
      })

    }
  }

  save() {
    if (this.mode === 'create') {
      this.create();
    } else if (this.mode === 'update') {
      this.update();
    }
  }

  toast(){
    this.dialogRef.close()

  
  }
  create(){}


  update(){}
  
}
