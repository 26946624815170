import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ToolbarserviceService {
  private apiUrl = 'https://api.exchangerate-api.com/v4/latest/USD'; 
  url_logout:string
  constructor(private http : HttpClient) { 
    this.url_logout=environment.url_logout

  }

  
  


  getlogout(){
    console.log("this.url_logout",this.url_logout)
    return this.http.get<any>(this.url_logout);

  }
}
