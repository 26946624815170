import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardSendingDataService {
  private FromDate = new BehaviorSubject<any>({});
  private ToDate = new BehaviorSubject<any>({});
  private Country = new BehaviorSubject<any>({});
  private City = new BehaviorSubject<any>({});
  private Hôtel = new BehaviorSubject<any>({});
  private Groupe = new BehaviorSubject<any>({});
  private Agency = new BehaviorSubject<any>({});
  private Vue = new BehaviorSubject<any>({});
  private Year = new BehaviorSubject<any>({});
  private Month = new BehaviorSubject<any>({});
  private Currency =new BehaviorSubject<any>({})

  DateFrom=this.FromDate.asObservable();
  DateTo=this.ToDate.asObservable();
  Pays=this.Country.asObservable();
  Ville=this.City.asObservable();
  Hotel=this.Hôtel.asObservable();
  Group=this.Groupe.asObservable();
  Agence=this.Agency.asObservable();
  Affichage=this.Vue.asObservable();
  annee=this.Year.asObservable();
  mois=this.Month.asObservable();
  currency=this.Currency.asObservable();





  constructor() { }
  UpdateFromDate(fromdate){
    this.FromDate.next(fromdate)
  }
  UpdateToDate(todate){
    this.ToDate.next(todate)
  }
  UpdateCountry(country){
    this.Country.next(country)
  }
  UpdateCity(city){
    this.City.next(city)
  }
  UpdateHotel(hotel){
    this.Hôtel.next(hotel)
  }
  UpdateGroupe(group){
    this.Groupe.next(group)
  }
  UpdateAgence(agence){
    this.Agency.next(agence)
  }

  UpdateAffichage(affichage){
    this.Vue.next(affichage)
  }
  UpdateYear(year){
    this.Year.next(year)
  }
  UpdateMonth(month){
    this.Month.next(month)
  }

  UpdateCurrency(currency){
    this.Currency.next(currency)
  }
}
