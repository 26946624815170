import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplementsService {
  urlGet_supplements: string;

  constructor(private http: HttpClient) { 

    this.urlGet_supplements=environment.supplementsCreateUpdateGetDelete
  }


  postSuppelements(request: any): Observable<any> {
    return this.http.post<any>(this.urlGet_supplements, request);
  }
  putSuppelements(request: any): Observable<any> {
    return this.http.put<any>(this.urlGet_supplements, request);
  }
  getSuppelements(id: any): Observable<any> {
    return this.http.get<any>(this.urlGet_supplements+'/'+id);
  }
  deleteSuppelements(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_supplements+'/'+id);
  }
}
