import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import versionData from '../../../../version.json';
@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;
  fullyear
  version: string = 'V5.0.0';
  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.fullyear =(new Date()).getFullYear()
    this.version = versionData.version;

      console.log("version update ",this.version)
  }

  ngOnDestroy(): void {}
}
