<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
               [mobileQuery]="!(isDesktop$ | async)"
               class="vex-toolbar"></vex-toolbar>
</ng-template>

<ng-template #footerRef>
  <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef"></vex-layout>

<vex-config-panel-toggle *ngIf="(ResultSearch.source.value.HotelsSearch != undefined && FilterType == 'searching') ||FilterType == 'dashboard' "  (openConfig)="configpanel.open()" (openFilter)="configpanelfilter.open()"></vex-config-panel-toggle>

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel  [invisibleBackdrop]="true" position="right">
  <vex-config-panel></vex-config-panel>
</vex-sidebar>
<vex-sidebar  #configpanelfilter [invisibleBackdrop]="true" position="right">
  <vex-config-panel-filter [ResultSearch]="ResultSearch"></vex-config-panel-filter>
</vex-sidebar>

<!-- END CONFIGPANEL -->
