
<form  [formGroup]="formSupplements">
  <h4 class="body-2 text-secondary m-0 title">{{'Supplements' | translate}}</h4><br>
  <div class="flex flex-col">
    <div  *ngFor="let items of supplements.controls;let j=index ">
      <div class="cadre">
        <div formArrayName="supplements">

          <div [formGroupName]="j">
            <div class="flex flex-col sm:flex-row sm:gap-4">
   


              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>Choose Period</mat-label>
                <input matInput formControlName="period_name"
                  [matAutocomplete]="autoP">
                <mat-autocomplete #autoP="matAutocomplete"
                  (optionSelected)="onChangePeriodSupplements($event,j)">
                  <mat-option [value]="'0'">
                    {{'All Seasons'}}
                  </mat-option>
                  <mat-option *ngFor="let period of Periodlist" [value]="period">
                    {{period.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Number Adult' | translate}} </mat-label>
                <input matInput formControlName="adults" type="number" required>
              </mat-form-field> 


               <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Board' | translate}}</mat-label>
                <input matInput formControlName="board_name" (input)="onInputChange($event)"
                required  (focus)="triggerReset()"
                  [matAutocomplete]="autoo">
                <mat-autocomplete #autoo="matAutocomplete"
                  (optionSelected)="onChangeDestinationboardForSupplementss($event.option.value,j)">
                  <mat-option *ngFor="let Arrangement of filterBoardingChild$| async" [value]="Arrangement">
                    {{Arrangement.board_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            
            <div class="flex flex-col sm:flex-row sm:gap-4">

               <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Room' }}</mat-label>
                <input matInput formControlName="room_name" type="text" (focus)="triggerResetRoom()"
                required (input)="onInputChangeRoom($event)"
                  [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete"
                  (optionSelected)="onChangeRoomNameForSupplementss($event.option.value,j)">
                  <mat-option *ngFor="let room of fltAllRooms$| async" [value]="room">
                    {{room.room_name}}
                  </mat-option>
                </mat-autocomplete> 
              </mat-form-field> 
             

               <mat-form-field class="flex-1" appearance="fill">
                <mat-label>{{'Room Type' | translate}}</mat-label>
                <input matInput (input)="onInputChangeRoomTypes($event,j)" 
                formControlName="type_name" type="text" 
                required
                  [matAutocomplete]="auto3">
                <mat-autocomplete #auto3="matAutocomplete"
                  (optionSelected)="onChangeRoomTypeSupplements($event.option.value,j)">
                  <mat-option *ngFor="let Room of (filterRoomTypeContratForChild$[j]| async)" [value]="Room">
                    {{Room.room_type_name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            
            
            <div class="flex flex-col sm:flex-row sm:gap-4">
            

              <mat-form-field class="flex-1" appearance="fill">
               <mat-label>{{'Price' | translate}} </mat-label>
               <input matInput formControlName="price" type="number" required >
              </mat-form-field>
              <mat-form-field class="flex-1" appearance="fill">
               <mat-label>{{'Operation' }}</mat-label>
               <mat-select formControlName="operation"  required>
                 <mat-option [value]="option.value" *ngFor="let option of options">
                   {{ option.display }}
                 </mat-option>
               </mat-select>
             
              </mat-form-field> 

             

    

             
            </div>
             
          </div>
         
        
          <button matInput class="flex-1"mat-button color="warn"  (click)="RemoveitemsForSupplement(j)">-
            Remove </button><br>

        </div>
      </div>
                    

    </div>
  </div>
       
        <button (click)="AddnewrowSupplement(null)" style="border-radius: 1;width: fit-content;" mat-button color="primary" type="button"> +{{'Add New' | translate}}
        </button>
        <br><br>

      <br>
       <div  style="margin-right: 25px;">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button  color="primary" [disabled]="pressed" mat-flat-button type="submit"
          (click)="saveSupplement()">{{'Update Supplements' | translate}}</button>
      </div> 
</form> 
  
 
