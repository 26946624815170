import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'vex-reservations-update',
  templateUrl: './reservations-update.component.html',
  styleUrls: ['./reservations-update.component.scss']
})
export class ReservationsUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
