<form (ngSubmit)="save()" [formGroup]="form">

    <mat-dialog-content class="flex flex-col">
<br><br>

<p style="color: rgb(114, 114, 236);">
    <b >
      {{ data.message }}
    </b>
</p>



<div>
    <div *ngFor="let alert of alerts" [ngClass]="alert.type">
      {{ alert.message }}
    </div>
  </div>
  



    </mat-dialog-content><br>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button (click)=toast() color="primary" mat-flat-button type="submit">OK</button>
    </mat-dialog-actions>



</form>