export class Contrat {
  isSelected: boolean;
    id: number;
    Status:string;
    Email:string;
    PhoneNumber:string;
    Code_TVA:number;
    Name_Contrat:string;
    Name_Hotel:string;
    Version:number;
    From:Date;
    To:Date;
    access:string
    Begin:Date;
    End:Date;
    CodePeriod:string;
    NbPeriod:number;
    NamePeriod:string;
    Room_Name:string;
    Room_type:string;
    Pax:number;
    Min_Adulte:number;
    Max_Adulte:number;
    MaxPax:number;
    Access:string;
    RegisterCommerce:string;
    Child:number;
    CodeRoom:string;
    CodeType:string;
    FromBoard:Date;
    EndBoard:Date;
    ROOM:string;
    RoomType:string;
    Board:string;
    TypeCalcul:string;
    Price:number;
    EXTBPrice:number;
    isEdit: boolean;
  currency: any;

    constructor(contrat) {
      this.id = contrat.id;
      this.Status=contrat.Status;
      this.Email=contrat.Email;
      this.PhoneNumber=contrat.PhoneNumber;
      this.Code_TVA=contrat.Code_TVA;
      this.Name_Contrat=contrat.Name_Contrat;
      this.Name_Hotel=contrat.Name_Hotel;
      this.To=contrat.To;
      this.Begin=contrat.Begin;
      this.End=contrat.End;
      this.CodePeriod=contrat.CodePeriod;
      this.NbPeriod=contrat.NbPeriod;
      this.NamePeriod=contrat.NamePeriod;
      this.Room_Name=contrat.Room_Name;
      this.Room_type=contrat.Room_type;
      this.Pax=contrat.Pax;
      this.Min_Adulte=contrat.Min_Adulte;
      this.Max_Adulte=contrat.Max_Adulte;
      this.MaxPax=contrat.MaxPax;
      this.Version=contrat.Version;
      this.Access=contrat.Access;
      this.RegisterCommerce=contrat.RegisterCommerce;
      this.Child=contrat.Child;
      this.CodeRoom=contrat.CodeRoom;
      this.CodeType=contrat.CodeType;
      this.FromBoard=contrat.FromBoard;
       this.EndBoard=contrat.EndBoard;
       this.ROOM=contrat.ROOM;   
      this.RoomType=contrat.RoomType;
      this.Board=contrat.Board;
      this.TypeCalcul=contrat.TypeCalcul;
      this.Price=contrat.Price;
      this.EXTBPrice=contrat.EXTBPrice;
  
  
  
  }}
  export const ContratColumns = [
    {
      key: 'isSelected',
      type: 'isSelected',
      label: '',
    },
 
    {
      key: 'Room_Name',
      type: 'text',
      label: 'RoomName',
      required: true,
    },
    {
      key: 'Room_type',
      type: 'text',
      label: 'RoomType',
    },
    
    {
      key: 'Board',
      type: 'text',
      label: 'Board',
    },
    {
      key: 'EXTBPrice',
      type: 'text',
      label: 'EXTB/Price',
    },
    {
      key: 'TypeCalcul',
      type: 'text',
      label: 'TypeCalcul',
    },
    {
      key: 'isEdit',
      type: 'isEdit',
      label: '',
    },
  ];