import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContratComponent } from './contrat.component';
import { AffichageContratComponent } from './affichage-contrat/affichage-contrat.component';

const routes: Routes = [
  {
    path: '',
    component: ContratComponent,
     },
     {
      path: '', component: AffichageContratComponent
     }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratRoutingModule { }
