import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessUserService {
  urlretrieveGroupesUsersPermissions: string;
  urlUpdatePermissionUser:string;

  constructor(private http : HttpClient) { 

    this.urlretrieveGroupesUsersPermissions=environment.urlretrieveGroupesUsersPermissions
    this.urlUpdatePermissionUser=environment.urlUpdatePermissionUser
  }    


  
  getAccessModulesAgenciesGroupes(){
    return this.http.get<any>(this.urlretrieveGroupesUsersPermissions  );

    
  }
  UpdatePermissionUser(permission){
    return this.http.put<any>(this.urlUpdatePermissionUser , permission);

  }
}
