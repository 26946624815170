import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { AccessUserService } from './access-user.service';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { UntypedFormControl } from '@angular/forms';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation';

@Component({
  selector: 'vex-access-user',
  templateUrl: './access-user.component.html',
  styleUrls: ['./access-user.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ],
})
export class AccessUserComponent implements OnInit {
  dataSource: any;
  data$: any;
  update: any;
  modules: any;
  responseObject:any;
  layoutCtrl = new UntypedFormControl('boxed');

  @Input()
  columns: TableColumn<any>[] = [];
  searchCtrl = new UntypedFormControl();
  isModified:boolean=false
 

  constructor(private api: AccessUserService) { 

    const storedResponse = localStorage.getItem('access');

    // Parsing the retrieved string back into an object
    this.responseObject = storedResponse ? JSON.parse(storedResponse) : null;

    console.log("requestA", this.responseObject)
    this.columns = [
      { label: 'ID', property: 'id', type: 'number', visible: false },
      { label: 'Groupe', property: 'group_name', type: 'number', visible: true },
      { label: 'contrat', property: 'contrat', type: 'button', visible: this.responseObject.contrat },
      { label: 'hotel', property: 'hotel', type: 'button', visible: this.responseObject.hotel },
      { label: 'XMLIN', property: 'xml_in', type: 'button', visible: this.responseObject.xml_in },
      { label: 'XMLOUT', property: 'xml_out', type: 'button', visible: this.responseObject.xml_out },
      { label: 'ERP', property: 'erp', type: 'button', visible: this.responseObject.erp },
      { label: 'Package', property: 'package', type: 'button', visible: this.responseObject.package },
      { label: 'Event', property: 'event', type: 'button', visible: this.responseObject.event },
      { label: 'Profil', property: 'profil', type: 'button', visible: false },
      { label: 'Tour', property: 'tour', type: 'button', visible: this.responseObject.tour },
      { label: 'Transfer', property: 'transfer', type: 'button', visible: this.responseObject.transfer },
      // { label: 'Agence', property: 'agence', type: 'button', visible: true },
      { label: 'Booking', property: 'booking', type: 'button', visible: this.responseObject.booking },
      { label: 'Comparator', property: 'comparator', type: 'button', visible: this.responseObject.comparator },
      { label: 'Configuration', property: 'configuration', type: 'button', visible: this.responseObject.configuration },
      { label: 'Dashboard', property: 'dashboard', type: 'button', visible: this.responseObject.dashboard },
      { label: 'CRM', property: 'crm', type: 'button', visible: this.responseObject.crm },
      { label: 'Search', property: 'search', type: 'button', visible: this.responseObject.search },
      { label: 'Stop Sale', property: 'stop_sale', type: 'button', visible: this.responseObject.stop_sale },
      { label: 'User', property: 'user', type: 'button', visible: this.responseObject.user },
      { label: 'Log', property: 'log', type: 'button', visible: this.responseObject.log },


    ];
    this.columns = this.columns.filter(
      
      column =>  column.property === 'group_name' || this.responseObject[column.property] === true



    );

  }

  ngOnInit(): void {

    this.api.getAccessModulesAgenciesGroupes().subscribe(res => {
      console.log("getAccessModulesAgenciesGroupes ", res)
      this.modules = res



      this.dataSource = new MatTableDataSource(res);
      console.log(" this.dataSource ",  this.dataSource)
    


    
    
      })}

      get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
      }
      trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
      }

      onCheckboxChange(event: any, row: any, property: string,i) {
        // Determine the new value of the checkbox
        const checked = event.checked;
        this.modules[i][property]=checked
        this.isModified=true
  
      }
      save(){
        console.log("req UpdatePermissionUser",this.modules)
        this.api.UpdatePermissionUser({permissions:this.modules}).subscribe(res => {
        console.log("res UpdatePermissionUser",res)

})

      }
 

   
}
