import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertserviceService {
  private alertsSource = new BehaviorSubject<{ message: string, type: string }[]>([]);
  alerts = this.alertsSource.asObservable();

  constructor(private http : HttpClient) { }


  addAlert(message: string, type: string) {
    const currentAlerts = this.alertsSource.getValue();
    this.alertsSource.next([...currentAlerts, { message, type }]);
  }

  clearAlerts() {
    this.alertsSource.next([]);
  }
}
