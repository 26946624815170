import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratRoutingModule } from './contrat-routing.module';
import { CreateContratComponent } from './create-contrat/create-contrat.component';
import { FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { MatStepperModule } from '@angular/material/stepper';
import { CreateContratModule } from './create-contrat/create-contrat.module';
import { ContratComponent } from './contrat.component';
import { AffichageContratComponent } from './affichage-contrat/affichage-contrat.component';
import { AffichageContratModule } from './affichage-contrat/affichage-contrat.module';
import { ScrollbarModule } from 'src/@vex/components/scrollbar/scrollbar.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CancellationModule } from './cancellation/cancellation.module';



@NgModule({
  declarations: [
    ContratComponent,
      ],
  imports: [
    CommonModule,
    ContratRoutingModule,
    PageLayoutModule,
    BreadcrumbsModule,
    CreateContratModule,
    AffichageContratModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatStepperModule,
    ScrollbarModule,SecondaryToolbarModule,
    MatSlideToggleModule,CreateContratModule,
    CancellationModule

    
  ],
  exports: [ContratComponent],
  schemas: [NO_ERRORS_SCHEMA], 
})
export class ContratModule { }



 