import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vex-config-panel-filter-toggle',
  templateUrl: './config-panel-filter-toggle.component.html',
  styleUrls: ['./config-panel-filter-toggle.component.scss']
})
export class ConfigPanelFilterToggleComponent implements OnInit {

 
  @Output() openConfig = new EventEmitter();
  @Output() openFilter = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}