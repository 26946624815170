export class Facture {
  isSelected: boolean;
    id: number;
    

    constructor(contrat) {
      this.id = contrat.id;
     
  
  }}
