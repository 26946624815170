import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute ,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
import { AuthService } from './auth.service';
import { JWTTokenService } from './JWTToken.service';
import { Observable } from 'rxjs'; 
import { LoginService } from './login/login.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: AuthService, 
    public router: Router, 
    private route: ActivatedRoute,
    private jwtService: JWTTokenService,
    public loginService: LoginService,
  ) { }
  /*
  canActivate(): boolean {

    //this.route.queryParams.subscribe(params => { token = params.token; });

    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }*/

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | boolean {
      console.log("this.jwtService.getUser()",this.jwtService.getUser())
      if (this.jwtService.getUser()) {
        console.log("this.jwtService.isTokenExpired()",this.jwtService.isTokenExpired())
          if (this.jwtService.isTokenExpired()) {
            this.router.navigate(['login']); // Should Redirect Sign-In Page
          } else {
            return true;
          }
      } else {       
        this.router.navigate(['login']); // Should Redirect Sign-In Page
        //return true;
      }
    }
}
