import { Injectable } from '@angular/core';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    //public jwtHelper: JwtHelperService,
    private route: ActivatedRoute,
  ) {
    //this.jwtHelper = new JwtHelperService();
  }

/*
  public isAuthenticated(): boolean {
    let token = localStorage.getItem("token")
    console.log(" token", token)
    if (!token) {
      return false;
    } else {
      return true
    }
 
  }*/
}